.resume-main {
	background-color: transparent;
	overflow-y: auto;

	.contact-info {
		background-color: #225969;
		margin-top: 90px;
		padding: 20px;
		justify-content: center;
		text-align: center;
		border-radius: 16px;
		border: 1px solid rgba(white, 0.5);
	}

	.main-content {
		grid-area: main-content;
		margin-left: 80px;
	}
}